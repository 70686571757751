<script setup lang="ts">
import { watchEffect } from "vue";
import { usePage } from "@inertiajs/vue3";
import { toast, Toaster } from "vue-sonner";

watchEffect(async () => {
  const message = usePage().props.flash?.toast?.message;
  const type = usePage().props.flash?.toast?.type;

  if (!message) return;

  if (type === "success") toast.success(message, {});
  else if (type === "error") toast.error(message, {});
  else if (type === "info") toast.info(message, {});
  else if (type === "warning") toast.warning(message, {});
});
</script>

<template>
  <Toaster position="bottom-right" closeButton />
</template>
